@use 'sass:math';

$desktop: 1599px;
$desktop1400: 1400px;
$desktop-Resize1400: 1399px;
// resize 1399px > 1200px 
$tablet1200: 1200px;
$tablet-Resize1200: 1199px;
// resize 1199px > 900px 
$tablet900: 900px;
$tablet-Resize900: 899px;
// resize 900px > 768px 
$tablet: 768px;
$mobile: 767px;

@mixin desktop {
   @media screen and (max-width: $desktop) {
      @content;
   }
}

@mixin desktop1400 {
   @media screen and (max-width: $desktop1400) {
      @content;
   }
}

@mixin desktop-Resize1400 {
   @media screen and (max-width: $desktop-Resize1400) {
      @content;
   }
}

@mixin tablet1200 {
   @media screen and (max-width: $tablet1200) {
      @content;
   }
}

@mixin tablet-Resize1200 {
   @media screen and (max-width: $tablet-Resize1200) {
      @content;
   }
}

@mixin tablet900 {
   @media screen and (max-width: $tablet900) {
      @content;
   }
}

@mixin tablet-Resize900 {
   @media screen and (max-width: $tablet-Resize900) {
      @content;
   }
}

@mixin tablet {
   @media screen and (max-width: $tablet) {
      @content;
   }
}

@mixin mobile {
   @media screen and (max-width: $mobile) {
      @content;
   }
}

@mixin hover {
   @media (hover: hover) {
      &:hover {
         @content;
      }
   }

   @media (hover: none) {
      &:active {
         @content;
      }
   }
}

@function fluid-media($max: 48, $min: 16) {
   $maxViewportWidth: 1920;
   $minViewportWidth: 1600;

   $maxSize: math.div($max, 16);
   $minSize: math.div($min, 16);
   $maxWidth: math.div($maxViewportWidth, 16);
   $minWidth: math.div($minViewportWidth, 16);

   $slope: math.div(($maxSize - $minSize), ($maxWidth - $minWidth));
   $yAxisIntersection: -$minWidth * $slope + $minSize;

   @return clamp(#{$minSize * 1rem},
      #{$yAxisIntersection * 1rem} + #{$slope * 100vw},
      #{$maxSize * 1rem});
}