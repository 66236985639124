@use '../../../scss/media';

.ready_start {
   display: flex;
   flex-direction: column;
   row-gap: 40px;
   row-gap: media.fluid-media(40, 30);
   align-items: center;
   padding: 80px 0;
   padding: media.fluid-media(80, 60) 0;
   position: relative;
   clip-path: inset(0px -100% 0px -100%);
   margin-top: 100px;
   margin-top: media.fluid-media(100, 60);

   @include media.desktop {
      padding: 60px 0;
      row-gap: 30px;

      margin-top: 60px;
   }

   @include media.tablet1200 {
      padding: 70px 0;

      margin-top: 30px;
   }

   @include media.mobile {
      padding: 40px 0;
      aspect-ratio: 349/602;
      justify-content: center;
      align-items: center;
      max-height: 700px;
      width: 100%;
   }

   &::after {
      content: '';
      display: block;
      width: calc(100vw + 6px);
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #2898FF;
      z-index: -1;
      user-select: none;
      pointer-events: none;
   }

   &-text {
      color: #ECECEC;
      text-align: center;
      font-family: "Forma DJR Cyrilic Variable";
      font-size: 40px;
      font-size: media.fluid-media(40, 30);
      font-weight: 400;
      line-height: 44px;
      line-height: media.fluid-media(44, 34);
      max-width: 800px;
      position: relative;
      z-index: 3;

      @include media.desktop {
         font-size: 30px;
         line-height: 34px;
      }

      @include media.tablet1200 {
         font-size: 24px;
         line-height: 32px;
      }

      @include media.mobile {
         max-width: 300px;

         br {
            display: none;
         }
      }
   }

   &-image {
      display: flex;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 440px;
      max-width: media.fluid-media(440, 312);
      max-height: 290px;
      max-height: media.fluid-media(290, 222);
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      user-select: none;
      pointer-events: none;

      @include media.desktop {
         max-width: 312px;
         max-height: 222px;
      }

      @include media.tablet1200 {
         max-width: 242px;
      }

      @include media.mobile {
         max-width: 100%;
         height: auto;
         justify-content: center;
         align-items: flex-start;
      }

      &::after {
         content: '';
         display: block;
         width: 100%;
         max-width: 190px;
         max-width: media.fluid-media(190, 120);
         height: 100%;
         position: absolute;
         top: 0;
         z-index: 1;
         user-select: none;
         pointer-events: none;
         z-index: 23123213213;

         @include media.desktop {
            max-width: 120px;
         }

         @include media.tablet1200 {
            max-width: 80px;
         }

         @include media.mobile {
            height: 100px;
            max-width: 100vw;
            width: 100vw;
         }
      }

      &.left {
         left: 0px;

         @include media.mobile {
            left: 50%;
            transform: translateX(-50%);
            top: 30px;
         }

         &::after {
            background: linear-gradient(270deg, rgba(40, 152, 255, 0.00) 0%, rgba(40, 152, 255, 0.40) 22.69%, rgba(40, 152, 255, 0.80) 47.66%, #2898FF 69.63%);
            left: -40px;

            @include media.tablet1200 {
               left: -20px;
            }

            @include media.mobile {
               left: -18px;
               top: -30px;
               background: linear-gradient(0deg, rgba(40, 152, 255, 0.00) 0%, rgba(40, 152, 255, 0.40) 22.69%, rgba(40, 152, 255, 0.80) 47.66%, #2898FF 69.63%);
            }
         }
      }

      &.right {
         right: 0px;

         @include media.mobile {
            left: 50%;
            transform: translateX(-50%);
            bottom: 30px;
            top: auto;
         }

         &::after {
            background: linear-gradient(90deg, rgba(40, 152, 255, 0.00) 0%, rgba(40, 152, 255, 0.40) 22.69%, rgba(40, 152, 255, 0.80) 47.66%, #2898FF 69.63%);
            right: -40px;

            @include media.tablet1200 {
               right: -20px;
            }

            @include media.mobile {
               left: -18px;
               bottom: -30px;
               top: auto;
               background: linear-gradient(180deg, rgba(40, 152, 255, 0.00) 0%, rgba(40, 152, 255, 0.40) 22.69%, rgba(40, 152, 255, 0.80) 47.66%, #2898FF 69.63%);
            }
         }
      }
   }

   &-btn {
      position: relative;
      z-index: 3;
      color: #fff;
      transition: .4s;

      .header_wrap_contact_us-it {
         color: #FFF;
      }

      @include media.hover {
         opacity: .7;
      }
   }

   &-elipse {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1540px;
      width: calc(100% - 300px);
      height: 100%;
      background: radial-gradient(50% 50% at 50% 50%, #2898FF 0%, rgba(40, 152, 255, 0.80) 50%, rgba(40, 152, 255, 0.00) 100%);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      user-select: none;
      pointer-events: none;

      @include media.desktop {
         width: 100%;
      }
   }
}