@import '../../../scss/media';

.coconessa {
   margin-top: 60px;
   margin-top: clamp(2.5rem, -3.75rem + 6.25vw, 3.75rem);
   // 60px > 40px

   @include desktop {
      margin-top: 40px;
   }

   @include tablet1200 {
      margin-top: 12px;
   }
}

.case_project {
   &_top {
      &_info {
         display: flex;
         flex-direction: column;
         row-gap: 20px;
         justify-content: space-between;
         height: 100%;

         &_head {
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            &-title {
               color: #1A1A1A;
               font-size: 50px;
               font-size: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
               // 50px > 40px
               font-weight: 400;
               line-height: 60px;
               line-height: clamp(3.125rem, 0rem + 3.125vw, 3.75rem);
               // 60px > 50px

               @include desktop {
                  font-size: 40px;
                  line-height: 50px;
               }
            }

            &-text {
               color: #252525;
               font-size: 22px;
               font-size: clamp(1.125rem, -0.125rem + 1.25vw, 1.375rem);
               // 22px > 18px
               font-weight: 400;
               line-height: 28px;
               line-height: clamp(1.5rem, 0.25rem + 1.25vw, 1.75rem);
               // 28px > 24px
               max-width: 380px;
               max-width: clamp(20.313rem, 3.125rem + 17.188vw, 23.75rem);
               // 380px > 325px

               @include desktop {
                  max-width: 325px;
                  font-size: 18px;
                  line-height: 24px;
               }
            }
         }

         &_team {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            row-gap: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
            // 20px > 16px

            @include desktop {
               row-gap: 16px;
            }

            &-title {
               color: #8A8A8A;
               font-size: 18px;
               font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
               // 18px > 14px
               font-weight: 400;
               line-height: 20px;
               line-height: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
               // 20px > 16px

               @include desktop {
                  font-size: 14px;
                  line-height: 16px;
               }
            }

            &_list {
               display: flex;
               flex-wrap: wrap;
               flex-direction: row-reverse;
               justify-content: flex-end;

               &-it {
                  width: 100%;
                  max-width: 100px;
                  max-width: clamp(5rem, -1.25rem + 6.25vw, 6.25rem);
                  // 100px > 80px
                  border-radius: 50%;
                  border: 6px solid #FFF;
                  overflow: hidden;
                  filter: grayscale(1);

                  @include desktop {
                     max-width: 80px;
                  }

                  &:not(:last-of-type) {
                     margin-left: -16px;
                  }

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }
            }
         }

         &-image {
            width: 100%;
            border-radius: 2px;
            overflow: hidden;
            aspect-ratio: 421/275;
            height: 100%;
            position: relative;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }

            &-link {
               position: absolute;
               bottom: 20px;
               right: 20px;
               display: flex;
               width: 42px;
               width: clamp(2rem, -1.125rem + 3.125vw, 2.625rem);
               // 42px > 32px
               height: 42px;
               height: clamp(2rem, -1.125rem + 3.125vw, 2.625rem);
               // 42px > 32px
               overflow: hidden;
               transition: .4s;

               @include hover {
                  opacity: .7;
               }

               @include desktop {
                  width: 32px;
                  height: 32px;
               }

               svg {
                  width: 100%;
                  height: 100%;
               }
            }
         }
      }
   }

   &_body {

      &_section {
         display: flex;
         flex-direction: column;
         margin-top: 80px;
         margin-bottom: 80px;
         row-gap: 50px;

         &:last-of-type {
            margin-bottom: 0px;
         }
      }

      &_section_images {
         row-gap: 20px;
      }

      &_section_text {
         display: flex;
         flex-wrap: wrap;
      }

      &_image {
         width: 100%;
         border-radius: 2px;
         overflow: hidden;
         height: auto;

         &.square {
            aspect-ratio: 1/1;
         }

         &.hor {
            aspect-ratio: 421/230;

            &.big {
               aspect-ratio: 368/189;

               @include desktop {
                  aspect-ratio: 68/45;
               }
            }
         }

         &.vertical {
            aspect-ratio: 67/80;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      &-small_text {
         color: #252525;
         font-size: 22px;
         font-size: clamp(1.125rem, -0.125rem + 1.25vw, 1.375rem);
         // 22px > 18px
         font-weight: 400;
         line-height: 28px;
         line-height: clamp(1.5rem, 0.25rem + 1.25vw, 1.75rem);
         // 28px > 24px

         @include desktop {
            font-size: 18px;
            line-height: 24px;
         }
      }

      &-middle_text {
         color: #252525;
         font-size: 26px;
         font-size: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
         // 26px > 22px
         font-weight: 400;
         line-height: 32px;
         line-height: clamp(1.75rem, 0.5rem + 1.25vw, 2rem);
         // 32px > 28px

         @include desktop {
            font-size: 22px;
            line-height: 28px;
         }
      }

      .blockquote {
         display: flex;
         flex-direction: column;
         row-gap: 40px;
         max-width: 800px;
         max-width: clamp(41.875rem, 1.25rem + 40.625vw, 50rem);
         // 800px > 670px
         align-items: center;
         margin-left: auto;
         margin-right: auto;

         @include desktop {
            max-width: 670px;
         }

         &-text {
            text-align: center;
         }

         &_author {
            display: flex;
            column-gap: 20px;
            align-items: center;

            &-image {
               width: 100%;
               max-width: 100px;
               max-width: clamp(5rem, -1.25rem + 6.25vw, 6.25rem);
               // 100px > 80px
               aspect-ratio: 1/1;
               height: auto;
               border-radius: 50%;
               overflow: hidden;

               @include desktop {
                  max-width: 80px;
               }

               img {
                  width: 100%;
                  height: 100%;
               }
            }

            &_meta {
               display: flex;
               flex-direction: column;
               row-gap: 4px;
               height: 100%;
               justify-content: center;
               width: 100%;
               max-width: calc(100% - 100px - 20px);
               max-width: calc(100% - clamp(5rem, -1.25rem + 6.25vw, 6.25rem) - 20px);
               // 100px > 80px

               @include desktop {
                  max-width: calc(100% - 80px - 20px);
               }

               &-name {
                  color: #252525;
                  font-size: 22px;
                  font-size: clamp(1.125rem, -0.125rem + 1.25vw, 1.375rem);
                  // 22px > 18ox
                  font-weight: 400;
                  line-height: 28px;
                  line-height: clamp(1.5rem, 0.25rem + 1.25vw, 1.75rem);
                  // 28px > 24px

                  @include desktop {
                     font-size: 18px;
                     line-height: 24px;
                  }
               }

               &-job {
                  color: #8A8A8A;
                  font-size: 18px;
                  font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
                  // 18px > 14px
                  font-weight: 400;
                  line-height: 20px;
                  line-height: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
                  // 20px > 16px

                  @include desktop {
                     font-size: 14px;
                     line-height: 16px;
                  }
               }
            }
         }
      }
   }
}