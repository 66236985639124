@font-face {
   src: url('../fonts/FormaDJRCyrillicVariable-VF-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Variable';
   font-style: normal;
   font-weight: normal;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicMicro-ExtraLight-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Micro';
   font-style: normal;
   font-weight: 275;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicMicro-Light-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Micro';
   font-style: normal;
   font-weight: 300;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicMicro-Regular-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Micro';
   font-style: normal;
   font-weight: normal;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicMicro-Medium-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Micro';
   font-style: normal;
   font-weight: 500;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicMicro-Bold-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Micro';
   font-style: normal;
   font-weight: bold;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicText-ExtraLight-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Text';
   font-style: normal;
   font-weight: 275;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicText-Light-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Text';
   font-style: normal;
   font-weight: 300;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicText-Regular-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Text';
   font-style: normal;
   font-weight: normal;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicText-Medium-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Text';
   font-style: normal;
   font-weight: 500;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicText-Bold-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Text';
   font-style: normal;
   font-weight: bold;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDeck-ExtraLight-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Deck';
   font-style: normal;
   font-weight: 275;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDeck-Light-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Deck';
   font-style: normal;
   font-weight: 300;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDeck-Regular-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Deck';
   font-style: normal;
   font-weight: normal;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDeck-Medium-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Deck';
   font-style: normal;
   font-weight: 500;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDeck-Bold-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Deck';
   font-style: normal;
   font-weight: bold;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDisplay-ExtraLight-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Display';
   font-style: normal;
   font-weight: 275;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDisplay-Light-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Display';
   font-style: normal;
   font-weight: 300;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDisplay-Regular-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Display';
   font-style: normal;
   font-weight: normal;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDisplay-Medium-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Display';
   font-style: normal;
   font-weight: 500;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicDisplay-Bold-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Display';
   font-style: normal;
   font-weight: bold;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicBanner-ExtraLight-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Banner';
   font-style: normal;
   font-weight: 275;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicBanner-Light-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Banner';
   font-style: normal;
   font-weight: 300;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicBanner-Regular-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Banner';
   font-style: normal;
   font-weight: normal;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicBanner-Medium-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Banner';
   font-style: normal;
   font-weight: 500;
   font-display: swap;
}

@font-face {
   src: url('../fonts/FormaDJRCyrillicBanner-Bold-Testing.woff2') format("woff2");
   font-family: 'Forma DJR Cyrilic Banner';
   font-style: normal;
   font-weight: bold;
   font-display: swap;
}



@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-Thin.woff2') format('woff2');
   font-weight: 100;
   font-style: normal;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-SemiBold.woff2') format('woff2');
   font-weight: 600;
   font-style: normal;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-Bold.woff2') format('woff2');
   font-weight: bold;
   font-style: normal;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-SemiBoldItalic.woff2') format('woff2');
   font-weight: 600;
   font-style: italic;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-Regular.woff2') format('woff2');
   font-weight: normal;
   font-style: normal;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-ThinItalic.woff2') format('woff2');
   font-weight: 100;
   font-style: italic;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-Medium.woff2') format('woff2');
   font-weight: 500;
   font-style: normal;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-MediumItalic.woff2') format('woff2');
   font-weight: 500;
   font-style: italic;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-LightItalic.woff2') format('woff2');
   font-weight: 300;
   font-style: italic;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-BoldItalic.woff2') format('woff2');
   font-weight: bold;
   font-style: italic;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-Light.woff2') format('woff2');
   font-weight: 300;
   font-style: normal;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-Italic.woff2') format('woff2');
   font-weight: normal;
   font-style: italic;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-ExtraLight.woff2') format('woff2');
   font-weight: 200;
   font-style: normal;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-ExtraBoldItalic.woff2') format('woff2');
   font-weight: bold;
   font-style: italic;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-ExtraBold.woff2') format('woff2');
   font-weight: bold;
   font-style: normal;
   font-display: swap;

}

@font-face {
   font-family: 'Pitagon Sans Mono';
   src: url('../fonts/PitagonSansMono-ExtraLightItalic.woff2') format('woff2');
   font-weight: 200;
   font-style: italic;
   font-display: swap;

}