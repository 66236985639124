@use '../../../scss/media';

.development_page_wrap_reviews {
   margin-top: 100px;
   margin-top: media.fluid-media(100, 60);

   @include media.desktop {
      margin-top: 60px;
   }

   @include media.tablet1200 {
      margin-top: 30px;
   }

   @include media.mobile {
      position: relative;
   }

   &-title {
      color: #323232;
      font-family: "Forma DJR Cyrilic Variable";
      font-size: 28px;
      font-size: media.fluid-media(28, 18);
      font-weight: 400;
      line-height: 38px;
      line-height: media.fluid-media(38, 24);

      @include media.desktop {
         font-size: 18px;
         line-height: 24px;
      }

      @include media.mobile {
         font-size: 24px;
         line-height: 30px;
      }
   }

   &_swiper {
      margin-top: 60px;
      margin-top: media.fluid-media(60, 40);

      @include media.desktop {
         margin-top: 40px;
      }

      @include media.tablet1200 {
         margin-top: 30px;
      }

      @include media.mobile {
         margin-top: 26px;
      }

      &_sw {

         @include media.mobile {
            overflow: visible;
         }

         &_item {
            display: flex;
            justify-content: space-between;

            @include media.tablet1200 {
               flex-wrap: wrap;
               row-gap: 30px;
            }

            @include media.mobile {
               row-gap: 26px;
            }

            &_author {
               display: flex;
               flex-direction: column;
               row-gap: 30px;
               width: 100%;
               max-width: 16.3%;

               @include media.desktop {
                  row-gap: 20px;
               }

               @include media.tablet1200 {
                  flex-direction: row;
                  column-gap: 20px;
                  max-width: 44%;
               }

               @include media.mobile {
                  column-gap: 16px;
                  max-width: 100%;
               }

               &-avatar {
                  display: block;
                  width: 100%;
                  height: 120px;
                  max-width: 120px;
                  min-width: 120px;
                  border-radius: 2px;
                  background: rgba(0, 0, 0, 0.04);
                  overflow: hidden;
                  aspect-ratio: 1/1;

                  @include media.desktop {
                     height: 153px;
                     max-width: 153px;
                     min-width: 153px;
                  }

                  @include media.tablet1200 {
                     height: 82px;
                     max-width: 82px;
                     min-width: 82px;
                  }

                  @include media.mobile {
                     height: 60px;
                     max-width: 60px;
                     min-width: 60px;
                  }

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               &-name {
                  color: #8A8A8A;
                  font-family: "Pitagon Sans Mono";
                  font-size: 20px;
                  font-size: media.fluid-media(20, 14);
                  font-weight: 400;
                  line-height: 28px;
                  line-height: media.fluid-media(28, 22);

                  @include media.desktop {
                     font-size: 14px;
                     line-height: 22px;
                  }

                  @include media.tablet1200 {
                     display: flex;
                     align-items: center;
                  }

                  @include media.mobile {
                     font-size: 12px;
                     line-height: 18px;
                  }
               }
            }

            &_content {
               display: flex;
               flex-direction: column;
               row-gap: 40px;
               row-gap: media.fluid-media(40, 30);
               width: 100%;
               max-width: 71%;

               @include media.desktop {
                  row-gap: 30px;
               }

               @include media.tablet1200 {
                  max-width: 100%;
               }

               &-text {
                  color: #1A1A1A;
                  font-family: "Forma DJR Cyrilic Variable";
                  font-size: 40px;
                  font-weight: 400;
                  line-height: 50px;

                  @include media.desktop {
                     font-size: 30px;
                     line-height: 34px;
                  }

                  @include media.tablet1200 {
                     font-size: 18px;
                     line-height: 26px;
                  }
               }

               &-btn {
                  opacity: 1;

                  .case_btn-btn {}

                  .case_btn-icon {}

                  @include media.hover {
                     .case_btn-btn {
                        transform: translateX(32px);
                     }

                     .case_btn-icon {
                        opacity: 1;
                     }
                  }
               }
            }

            &-btn {
               @include media.tablet1200 {
                  .case_btn-icon {
                     opacity: 1;
                     position: unset;
                  }
               }

               @include media.mobile {
                  width: 100%;
               }
            }
         }
      }

      &_btns {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         position: relative;
         column-gap: 10px;
         column-gap: media.fluid-media(10, 6);

         @include media.desktop {
            column-gap: 6px;
         }

         @include media.mobile {
            position: absolute;
            top: 0;
            right: 0px;
         }

         &-prev {
            display: flex;
            width: 50px;
            width: media.fluid-media(50, 40);
            height: 50px;
            height: media.fluid-media(50, 40);
            border-radius: 50%;
            aspect-ratio: 1/1;
            overflow: hidden;
            position: unset;
            margin: 0;
            padding: 0;

            transition: .4s;

            @include media.hover {
               svg {
                  rect {
                     fill: #494949;
                  }

                  path {
                     stroke: #FFF;
                  }
               }
            }

            @include media.desktop {
               width: 40px;
               height: 40px;
            }

            @include media.tablet1200 {
               width: 30px;
               height: 30px;
            }

            &.swiper-button-lock {
               display: none;
            }

            &::after {
               display: none;
            }

            svg {
               width: 100%;
               height: 100%;
            }
         }

         &-next {
            display: flex;
            width: 50px;
            width: media.fluid-media(50, 40);
            height: 50px;
            height: media.fluid-media(50, 40);
            border-radius: 50%;
            aspect-ratio: 1/1;
            overflow: hidden;
            position: unset;
            margin: 0;
            padding: 0;

            transition: .4s;

            @include media.hover {
               svg {
                  rect {
                     fill: #494949;
                  }

                  path {
                     stroke: #FFF;
                  }
               }
            }

            @include media.desktop {
               width: 40px;
               height: 40px;
            }

            @include media.tablet1200 {
               width: 30px;
               height: 30px;
            }

            &.swiper-button-lock {
               display: none;
            }

            &::after {
               display: none;
            }

            svg {
               width: 100%;
               height: 100%;
            }
         }
      }
   }
}