@use '../../../scss/media';

.development_page_wrap_tech {
   position: relative;
   padding-top: 60px;
   padding-top: media.fluid-media(60, 40);
   padding-bottom: 80px;
   padding-bottom: media.fluid-media(80, 60);
   margin-top: 60px;

   @include media.desktop {
      padding-top: 40px;
      padding-bottom: 60px;
   }

   @include media.tablet1200 {
      margin-top: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
      row-gap: 30px;
   }

   &::after {
      content: '';
      display: block;
      width: calc(100vw + 10px);
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #303030;
      user-select: none;
      pointer-events: none;
      z-index: -1;
   }

   &-title {
      color: #8A8A8A;
      font-family: "Forma DJR Cyrilic Variable";
      font-size: 28px;
      font-size: media.fluid-media(28, 18);
      font-weight: 400;
      line-height: 38px;
      line-height: media.fluid-media(38, 24);

      @include media.desktop {
         font-size: 18px;
         line-height: 24px;
      }

      @include media.tablet1200 {
         font-size: 12px;
         line-height: 14px;
      }
   }

   &_items {
      display: flex;
      justify-content: space-between;
      margin: 0 -30px;
      margin: 0 calc(0px - media.fluid-media(30, 20));
      flex-wrap: wrap;
      row-gap: 30px;

      @include media.desktop {
         margin: 0 -20px;
      }

      &_it {
         display: flex;
         flex-direction: column;
         row-gap: 30px;
         width: 100%;
         max-width: 340px;
         max-width: 33.3333333333%;
         padding: 0 30px;
         padding: 0 media.fluid-media(30, 20);

         @include media.desktop {
            padding: 0 20px;
         }

         @include media.tablet1200 {
            row-gap: 24px;
         }

         @include media.mobile {
            max-width: 100%;
         }

         &-title {
            display: flex;
            column-gap: 20px;
            column-gap: media.fluid-media(20, 10);
            color: #8A8A8A;
            font-family: "Forma DJR Cyrilic Variable";
            font-size: 20px;
            font-size: media.fluid-media(20, 18);
            font-weight: 400;
            line-height: 28px;
            line-height: media.fluid-media(28, 24);

            @include media.desktop {
               font-size: 18px;
               line-height: 24px;
               column-gap: 10px;
            }

            @include media.tablet1200 {
               font-size: 14px;
               line-height: 16px;
            }


            &-num {}

            span {}
         }

         &_list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            &-tech {
               display: inline-flex;
               justify-content: center;
               align-items: center;
               column-gap: 10px;
               padding: 10px 20px;
               border-radius: 50px;
               background: #494949;

               color: #ECECEC;
               font-family: "Forma DJR Cyrilic Variable";
               font-size: 20px;
               font-size: media.fluid-media(20, 18);
               font-weight: 400;
               line-height: 28px;
               line-height: media.fluid-media(28, 24);

               @include media.desktop {
                  font-size: 18px;
                  line-height: 24px;
               }

               .icon {
                  display: flex;
                  width: 24px;
                  width: media.fluid-media(24, 18);
                  min-width: 24px;
                  min-width: media.fluid-media(24, 18);
                  height: 24px;
                  height: media.fluid-media(24, 18);

                  @include media.desktop {
                     width: 18px;
                     min-width: 18px;
                     height: 18px;
                  }

                  svg {
                     width: 100%;
                     height: 100%;
                  }
               }

               span {}
            }
         }
      }
   }
}