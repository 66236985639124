@import '../../../scss/media';

.front_case {
   margin-top: 160px;

   @include desktop1400 {
      margin-top: 100px;
      margin-top: clamp(5rem, -2.5rem + 10vw, 6.25rem);
      // 100px > 80px
   }

   @include tablet1200 {
      margin-top: 80px;
      margin-top: clamp(4.375rem, 2.5rem + 3.333vw, 5rem);
      // 80px > 70px
   }

   @include tablet900 {
      margin-top: 70px;
      margin-top: clamp(3.125rem, -4.148rem + 15.152vw, 4.375rem);
      // 70px > 50px
   }

   @include tablet {
      margin-top: 50px;
      margin-top: clamp(2.5rem, 0.923rem + 4.587vw, 3.125rem);
      // 50px > 40px
   }

   @include mobile {
      margin-top: 40px;
   }
}