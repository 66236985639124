@import '../../scss/media';

.case_list {
   row-gap: 180px;
   row-gap: clamp(8.75rem, -3.75rem + 12.5vw, 11.25rem);
   // 180px > 140px
   justify-content: space-between;

   @include desktop {
      row-gap: 140px;
   }

   @include desktop1400 {
      row-gap: 100px;
      row-gap: clamp(5rem, -2.5rem + 10vw, 6.25rem);
      // 100px > 80px
   }

   @include tablet1200 {
      row-gap: 80px;
      row-gap: clamp(4.375rem, 2.5rem + 3.333vw, 5rem);
      // 80px > 70px
   }

   @include tablet900 {
      row-gap: 70px;
      row-gap: clamp(3.125rem, -4.148rem + 15.152vw, 4.375rem);
      // 70px > 50px
   }

   @include tablet {
      row-gap: 50px;
      row-gap: clamp(2.5rem, 0.923rem + 4.587vw, 3.125rem);
      // 50px > 40px
   }

   @include mobile {
      row-gap: 40px;
   }

   .col_rev_case {

      @include mobile {
         article {
            row-gap: 8px;
         }
      }
   }
}

.case {
   justify-content: flex-start;
   align-items: flex-start;
   row-gap: 20px;

   @include desktop1400 {
      row-gap: 16px;
   }

   @include tablet1200 {
      row-gap: clamp(0.5rem, -1rem + 2.667vw, 1rem);
      // 16px > 8px
   }

   @include tablet900 {
      row-gap: 8px;
   }

   @include hover {
      .case_image-img {

         img {
            transform: scale(1.04);
         }
      }

      .case_image-site_link {
         opacity: 1;
      }

      .case_btn {
         .icon {
            opacity: 1;
            transition: .4s;
         }

         &-btn {
            transform: translateX(32px);
         }
      }
   }

   &_image {
      width: 100%;
      border-radius: 2px;
      overflow: hidden;
      height: auto;
      position: relative;
      margin-bottom: 10px;
      margin-bottom: clamp(0rem, -3.125rem + 3.125vw, 0.625rem);
      //  10px > 0px

      @include desktop {
         margin-bottom: 0px;
      }

      @include mobile {
         display: flex;
         flex-direction: column;
         row-gap: 10px;
         aspect-ratio: unset;
      }

      &-img {
         display: block;
         width: 100%;
         height: 100%;
         position: relative;

         @include tablet-Resize900 {
            aspect-ratio: 1/1;
         }

         @include mobile {
            aspect-ratio: 349/300;
         }

         &_link {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
         }
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;

         transition: .5s;
      }

      &-site_link {
         display: flex;
         position: absolute;
         bottom: 20px;
         right: 20px;
         opacity: 0;
         width: 42px;
         width: clamp(2rem, -1.125rem + 3.125vw, 2.625rem);
         // 42px > 32px
         height: 42px;
         height: clamp(2rem, -1.125rem + 3.125vw, 2.625rem);
         // 42px > 32px

         transition: .4s;

         @include desktop {
            width: 32px;
            height: 32px;
         }

         @include desktop1400 {
            opacity: 1;
            bottom: 15px;
            right: 15px;
         }

         @include tablet1200 {
            bottom: clamp(0.625rem, -0.313rem + 1.667vw, 0.938rem);
            // 15px > 10px
            right: clamp(0.625rem, -0.313rem + 1.667vw, 0.938rem);
            // 15px > 10px
            width: clamp(1.875rem, 1.5rem + 0.667vw, 2rem);
            // 32px > 30px
            height: clamp(1.875rem, 1.5rem + 0.667vw, 2rem);
            // 32px > 30px
         }

         @include tablet900 {
            bottom: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
         }

         @include tablet-Resize900 {
            width: 32px;
            height: 32px;
            bottom: 15px;
            right: 15px;
         }

         @include tablet {
            right: 10px;
            bottom: 10px;
         }

         @include mobile {
            bottom: 10px;
            bottom: clamp(0.625rem, 0.199rem + 1.818vw, 0.938rem);
            // 15px > 10px
            right: 10px;
            right: clamp(0.625rem, 0.199rem + 1.818vw, 0.938rem);
            // 15px > 10px
         }

         svg {
            width: 100%;
            height: 100%;
         }

         @include hover {
            opacity: .7 !important;
         }
      }
   }

   .case_tags {
      position: absolute;
      top: 0;
      left: 0px;
      max-width: 100%;
      padding: 20px;

      @include desktop1400 {
         top: 10px;
         padding: 0px 10px;
      }

      @include mobile {
         position: unset;
         padding: 0px;
      }

      &_sw {

         @include desktop1400 {
            overflow: visible;
         }

         &-it {
            width: auto;

            &-item {
               display: inline-flex;
               justify-content: center;
               align-items: center;

               color: #FFF;
               font-family: "Pitagon Sans Mono";
               font-size: 16px;
               font-size: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
               // 16px > 14px
               font-weight: 400;
               line-height: 18px;
               line-height: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
               // 18px > 16px

               padding: 12px 16px;
               padding: clamp(0.5rem, -0.75rem + 1.25vw, 0.75rem) clamp(0.75rem, -0.5rem + 1.25vw, 1rem);
               // 12px > 8px
               // 16px > 12px

               border-radius: 2px;
               background: rgba(255, 255, 255, 0.20);
               backdrop-filter: blur(50px);
               overflow: hidden;


               @include desktop {
                  padding: 8px 12px;
                  font-size: 14px;
                  line-height: 16px;
               }

               @include tablet1200 {
                  font-size: clamp(0.75rem, 0.375rem + 0.667vw, 0.875rem);
                  // 14px > 12px
                  line-height: clamp(0.875rem, 0.5rem + 0.667vw, 1rem);
                  // 16px > 14px
                  padding: clamp(0.375rem, 0rem + 0.667vw, 0.5rem) clamp(0.625rem, 0.25rem + 0.667vw, 0.75rem);
                  // 8px > 6px
                  // 12px > 10px
               }

               @include tablet900 {
                  font-size: 12px;
                  line-height: 14px;
                  padding: 6px 10px;
               }

               @include mobile {
                  padding: 3px 0;
                  background: transparent;
                  backdrop-filter: unset;

                  color: #8A8A8A;
                  font-size: 12px;
                  line-height: 14px;
               }
            }
         }
      }
   }

   &-title-link {

      .case-title {
         transition: .4s;

         @include hover {
            color: #8A8A8A;
         }
      }
   }

   &-title {
      display: inline-flex;
      color: #323232;
      font-size: 40px;
      font-size: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
      // 40px > 30px
      font-weight: 400;
      line-height: 45px;
      line-height: clamp(2.125rem, -1.313rem + 3.438vw, 2.813rem);
      // 45px > 34px

      @include desktop {
         font-size: 30px;
         line-height: 34px;
      }

      @include tablet1200 {
         font-size: clamp(1.5rem, 0.375rem + 2vw, 1.875rem);
         // 30px > 24px
         line-height: clamp(1.75rem, 0.625rem + 2vw, 2.125rem);
         // 34px > 28px
      }

      @include tablet900 {
         font-size: 24px;
         line-height: 28px;
      }

      @include mobile {
         font-size: 24px;
         line-height: 30px;
      }
   }

   &-text {
      color: #323232;
      font-size: 20px;
      font-size: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
      // 20px > 16px
      font-weight: 400;
      line-height: 26px;
      line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
      // 26px > 22px

      @include desktop {
         font-size: 16px;
         line-height: 22px;
      }

      @include tablet1200 {
         font-size: clamp(0.875rem, 0.5rem + 0.667vw, 1rem);
         // 16px > 14px
         line-height: 18px;
         line-height: clamp(1.125rem, 0.375rem + 1.333vw, 1.375rem);
         // 22px > 18px
      }

      @include tablet900 {
         font-size: 14px;
         line-height: 18px;
      }

      @include mobile {
         font-size: 14px;
         line-height: 20px;
         margin-top: -4px;
      }
   }

   &_btn {
      display: flex;
      align-items: center;
      column-gap: 10px;
      position: relative;

      &-icon {
         display: flex;
         position: absolute;
         opacity: 0;
         left: 0px;
         transition: .2s;

         svg {
            g {
               circle {
                  animation-name: animateCircle;
                  animation-duration: 2.5s;
                  animation-iteration-count: infinite;
                  animation-fill-mode: forwards;
                  animation-timing-function: linear;
               }
            }
         }
      }

      &-btn {
         color: #8A8A8A;
         font-family: "Pitagon Sans Mono";
         font-size: 20px;
         font-size: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
         // 20px > 16px
         font-weight: 400;
         line-height: 22px;

         transform: translateX(0px);
         transition: .4s;

         @include desktop {
            font-size: 16px;
            line-height: 22px;
         }

         @include tablet1200 {
            font-size: clamp(0.875rem, 0.5rem + 0.667vw, 1rem);
            // 16px > 14px
            line-height: clamp(1.25rem, 0.875rem + 0.667vw, 1.375rem);
            // 22px > 20px
         }

         @include tablet900 {
            font-size: 14px;
            line-height: 20px;
         }

         @include mobile {
            font-size: 14px;
            line-height: 20px;
         }

         @include hover {
            color: #323232;
         }

         &::after {
            content: ' ->';
            display: inline;
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
         }
      }
   }

   &_benefit {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 10px;

      @include tablet900 {
         column-gap: 20px;
         row-gap: 0px;
         justify-content: flex-start;
      }

      &_sw {
         margin: 0;

         @include mobile {
            overflow: visible;
         }
      }

      &-it {
         color: #323232;
         font-family: "Pitagon Sans Mono";
         font-size: 20px;
         font-size: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
         // 20px > 16px
         font-weight: 400;
         line-height: 26px;
         line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
         // 26px > 22px

         @include desktop {
            font-size: 16px;
            line-height: 22px;
         }

         @include tablet1200 {
            font-size: clamp(0.875rem, 0.5rem + 0.667vw, 1rem);
            // 16px > 14px
            line-height: clamp(1.25rem, 0.875rem + 0.667vw, 1.375rem);
            // 22px > 20px
         }

         @include tablet900 {
            font-size: 14px;
            line-height: 20px;
            width: auto;
         }

         @include mobile {
            color: #252525;
            font-size: 12px;
            line-height: 14px;
            padding: 3px 0;
         }
      }
   }

   &_info {
      display: flex;
      flex-wrap: wrap;

      @include mobile {
         flex-direction: column;
         row-gap: 6px;
      }

      &_text {
         display: flex;
         flex-direction: column;
         justify-content: center;
         row-gap: 20px;

         @include desktop1400 {
            row-gap: 16px;
         }

         @include tablet1200 {
            row-gap: clamp(0.5rem, -1rem + 2.667vw, 1rem);
            // 16px > 8px
         }

         @include tablet900 {
            row-gap: 8px;
         }

         @include mobile {
            row-gap: 10px;
         }
      }

      .case-text {
         margin-top: 0px;
      }
   }

   &.small {
      display: flex;
      flex-direction: column;

      .case_image {
         aspect-ratio: 1/1;

         @include tablet-Resize900 {
            aspect-ratio: unset;
         }
      }

      @include mobile {
         row-gap: 10px;

         .case-text {
            margin-top: -4px;
         }
      }
   }

   &.middle {
      display: flex;
      flex-direction: column;
      justify-content: unset;
      align-items: unset;

      @include mobile {
         row-gap: 10px;
      }

      .case_image {
         aspect-ratio: 421/230;

         @include desktop1400 {
            aspect-ratio: 42/25;
         }

         @include tablet-Resize900 {
            aspect-ratio: unset;
         }
      }

      .case_info {

         @include tablet-Resize900 {
            flex-direction: column;
         }

         &-col_title {
            width: 100%;
            max-width: 33.333333%;

            @include tablet-Resize900 {
               max-width: 100%;
            }

            @include mobile {
               max-width: 100%;
            }
         }

         &_text {
            width: 100%;
            max-width: 66.666666%;

            @include tablet-Resize900 {
               max-width: 100%;
            }

            @include mobile {
               max-width: 100%;
            }
         }
      }
   }

   &.big {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      justify-content: space-between;
      align-items: unset;

      @include mobile {
         row-gap: 10px;
      }

      .case_image {
         aspect-ratio: 1840/947;

         @include desktop {
            aspect-ratio: 67/35;
         }

         @include tablet900 {
            aspect-ratio: 136/70;
         }

         @include mobile {
            aspect-ratio: unset;
         }
      }

      .case_info {

         &-col_title {
            width: 100%;
            max-width: 50%;

            @include tablet900 {
               max-width: 37.5%;
            }

            @include mobile {
               max-width: 100%;
            }
         }

         &_text {
            width: 100%;
            max-width: 50%;

            @include tablet900 {
               max-width: 62.5%;
            }

            @include mobile {
               max-width: 100%;
            }
         }
      }
   }

   &_avatar {
      display: inline-flex;
      position: relative;

      @include tablet1200 {
         margin-bottom: 10px;
      }

      @include mobile {
         margin-bottom: 0px;
         column-gap: 16px;
      }

      &-avatar {
         width: 100%;
         max-width: 100px;
         max-width: clamp(5rem, -1.25rem + 6.25vw, 6.25rem);
         // 100px > 80px
         height: 100%;
         border-radius: 50%;
         overflow: hidden;
         aspect-ratio: 1/1;

         @include desktop {
            max-width: 80px;
         }

         @include tablet1200 {
            max-width: clamp(3.875rem, 0.5rem + 6vw, 5rem);
            // 80px > 62px
         }

         @include tablet900 {
            max-width: 62px;
         }

         @include mobile {
            max-width: 60px;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      &-video_link {
         display: flex;
         align-items: center;
         column-gap: 10px;
         position: absolute;
         bottom: 0;
         left: 77px;
         left: clamp(3.75rem, -1.563rem + 5.313vw, 4.813rem);
         // 77px > 60px

         color: #2898FF;
         font-size: 16px;
         font-size: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
         // 16px > 14px
         font-weight: 400;
         line-height: 16px;

         transition: .4s;

         @include hover {
            opacity: .8;
         }

         @include desktop {
            font-size: 14px;
            line-height: 16px;

            left: 60px;
         }

         @include tablet1200 {
            left: clamp(2.688rem, -0.5rem + 5.667vw, 3.75rem);
            // 60px > 43px
         }

         @include tablet900 {
            left: 43px;
         }

         @include mobile {
            position: unset;

            font-size: 12px;
            line-height: 18px;
         }

         .icon {
            width: 40px;
            width: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
            // 40px > 30px
            height: 40px;
            height: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
            // 40px > 30px

            @include desktop {
               width: 30px;
               height: 30px;
            }

            @include tablet-Resize1200 {
               width: clamp(1.5rem, 0.375rem + 2vw, 1.875rem);
               // 30px > 24px
               height: clamp(1.5rem, 0.375rem + 2vw, 1.875rem);
               // 30px > 24px
            }

            @include tablet900 {
               width: 24px;
               height: 24px;
            }

            svg {
               width: 100%;
               height: 100%;
            }
         }
      }

      &_info {


         @include mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            max-width: calc(100% - 60px - 16px);
         }
      }
   }

   &-author_name {
      color: #8A8A8A;
      font-family: "Pitagon Sans Mono";
      font-size: 20px;
      font-size: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
      // 20px > 16px
      font-weight: 400;
      line-height: 26px;
      line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
      // 26px > 22px

      margin-top: 10px;
      margin-top: clamp(0rem, -3.125rem + 3.125vw, 0.625rem);
      // 10px > 0px

      @include desktop {
         font-size: 16px;
         font-size: clamp(0.875rem, 0.5rem + 0.667vw, 1rem);
         // 16px > 14px
         line-height: 22px;
         line-height: clamp(1.25rem, 0.875rem + 0.667vw, 1.375rem);
         // 22px > 20px

         margin-top: 0px;
      }

      @include desktop1400 {
         margin-top: 30px;
         margin-top: clamp(0.625rem, -6.875rem + 10vw, 1.875rem);
         // 30px > 10px
      }

      @include tablet1200 {
         margin-top: 0px;
      }

      @include tablet900 {
         font-size: 14px;
         line-height: 20px;
      }

      @include mobile {
         color: #8A8A8A;
         font-size: 12px;
         line-height: 18px;
      }
   }

   &.light {
      .case_tags_sw-it {
         &-item {
            background: #fff;
            color: #8A8A8A;
         }
      }

      .case_image-site_link {
         svg {
            rect {
               fill-opacity: 1;
            }

            path {
               fill: #323232;
            }
         }
      }
   }


}

.case_rev {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   row-gap: 20px;
   width: 100%;

   height: auto;
   aspect-ratio: 65/92;

   @include desktop1400 {
      aspect-ratio: 325/501;
   }

   @include tablet1200 {
      aspect-ratio: 275/427;
   }

   @include tablet-Resize1200 {
      aspect-ratio: 7426189494929326 / 7506585785152307;
      padding-left: 30px;
   }

   @include tablet900 {
      aspect-ratio: 316/317;
      padding-left: 30px;
   }

   @include tablet-Resize900 {
      aspect-ratio: 1/1;
      padding-left: 10px;
   }

   @include mobile {
      aspect-ratio: unset;
      height: auto;
      row-gap: 16px;
      padding-left: 0px;
   }

   &_info {
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      @include desktop1400 {
         // row-gap: clamp(0.625rem, -6.875rem + 10vw, 1.875rem);
         // // 30px > 10px
         row-gap: 0px;
      }

      @include tablet1200 {
         row-gap: 10px;
      }

      @include mobile {
         row-gap: 16px;
      }

      >* {
         &:first-child {
            margin-top: 0px !important;
         }
      }

      .case-text {
         margin-top: 10px;

         @include desktop1400 {
            margin-top: 30px;
            margin-top: clamp(1.25rem, -2.5rem + 5vw, 1.875rem);
            // 30px > 20px
         }

         @include tablet1200 {
            margin-top: 0px;
         }

         @include mobile {
            margin: 0px;
         }
      }
   }
}