@use '../../../scss/media';

.development_page_wrap_head {

   row-gap: 20px;

   &-title {
      @include media.tablet1200 {
         font-size: 24px;
         line-height: 30px;
      }
   }

   &-text {
      color: #252525;
      font-family: "Forma DJR Cyrilic Variable";
      font-size: 28px;
      font-size: media.fluid-media(28, 22);
      font-weight: 400;
      line-height: 38px;
      line-height: media.fluid-media(38, 28);

      @include media.desktop {
         font-size: 22px;
         line-height: 28px;
      }

      @include media.tablet1200 {
         font-size: 18px;
         line-height: 26px;
         max-width: 600px;
      }
   }
}