@import './scss/reset.scss',
'./scss/fonts.scss',
'./scss/media',
'./scss/custom-grid';

html,
body {
   overflow-x: clip;
   scroll-behavior: smooth;
}

html {
   overflow-y: hidden;
}

body {
   color: #323232;
   font-family: "Forma DJR Cyrilic Variable", sans-serif;
   background-color: #FFF;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
}

::-webkit-scrollbar {
   width: 5px;
}

::-webkit-scrollbar-track {
   background: #fff;
}

::-webkit-scrollbar-thumb {
   background: #8A8A8A;
}

#root {
   display: flex;
   flex-direction: column;
   min-height: 100dvh;
}

main {
   flex: 1 1 auto;

   @include tablet1200 {
      margin-top: 62px;
   }
}

.container {
   width: 100%;
   max-width: 100%;
   margin: 0 auto;
   padding: 0 40px;

   @include desktop {
      padding-left: clamp(1.25rem, -42.5rem + 50vw, 2.5rem);
      // 40px > 20px
      padding-right: clamp(1.25rem, -42.5rem + 50vw, 2.5rem);
      // 40px > 20px
   }

   @include desktop1400 {
      padding: 0 20px;
   }

   @include tablet {
      padding: 0 17px;
   }

   @include mobile {
      padding: 0 13px;
   }
}

.row {
   --bs-gutter-x: 20px;

   @include tablet900 {
      --bs-gutter-x: 10px;
   }

   @include mobile {
      --bs-gutter-x: 7px;
   }
}

@keyframes animateCircle {
   0% {
      r: 6,
   }

   50% {
      r: 3,
   }

   100% {
      r: 6,
   }
}

.page_title {
   color: #1A1A1A;
   font-family: "Forma DJR Cyrilic Variable";
   font-size: 56px;
   font-size: clamp(2.5rem, -2.5rem + 5vw, 3.5rem);
   // 56px > 40px
   font-weight: 400;
   line-height: 66px;
   line-height: clamp(3.125rem, -1.875rem + 5vw, 4.125rem);
   // 66px > 50px

   @include desktop {
      font-size: 40px;
      line-height: 50px;
   }
}

.section_title {
   color: #323232;
   font-family: "Forma DJR Cyrilic Variable";
   font-size: 30px;
   font-weight: 400;
   line-height: 34px;

   margin-bottom: 40px;
}

@keyframes glitch-anim-1 {
   0% {
      opacity: 1;
      transform: translateZ(0);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      filter: blur(1px);
   }

   2% {
      clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
      transform: translate(-5px)
   }

   6% {
      clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
      transform: translate(5px);
      filter: blur(1px);
   }

   8% {
      clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
      transform: translate(-5px)
   }

   9% {
      clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
      transform: translate(0);
      filter: blur(1px);
   }

   10% {
      clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
      transform: translate3d(5px, 0, 0)
   }

   13% {
      clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
      transform: translateZ(0);
      filter: blur(1px);
   }

   13.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      transform: translate3d(5px, 0, 0);
      filter: blur(1px);
   }

   15% {
      clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
      transform: translate3d(5px, 0, 0)
   }

   20% {
      clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
      transform: translate3d(-5px, 0, 0);
      filter: blur(1px);
   }

   20.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      transform: translate3d(5px, 0, 0)
   }

   25% {
      clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
      transform: translate3d(5px, 0, 0);
      filter: blur(1px);
   }

   30% {
      clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
      transform: translate3d(-5px, 0, 0)
   }

   30.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      filter: blur(1px);
   }

   35% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(-5px)
   }

   40% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(5px);
      filter: blur(1px);
   }

   45% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(-5px)
   }

   50% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(0);
      filter: blur(1px);
   }

   55% {
      clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
      transform: translate3d(5px, 0, 0)
   }

   60% {
      clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
      transform: translateZ(0);
      filter: blur(1px);
      opacity: 1
   }

   60.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      opacity: 1;
      filter: blur(1px);
   }

   to {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      opacity: 1
   }
}

@keyframes glitch-anim-2 {
   0% {
      opacity: 1;
      transform: translateZ(0);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      filter: blur(1px);
   }

   2% {
      clip-path: polygon(0 18%, 100% 18%, 100% 100%, 0 100%);
      transform: translate(-5px)
   }

   6% {
      clip-path: polygon(0 19%, 100% 19%, 100% 100%, 0 100%);
      transform: translate(5px);
      filter: blur(1px);
   }

   8% {
      clip-path: polygon(0 38%, 100% 38%, 100% 100%, 0 100%);
      transform: translate(-5px)
   }

   9% {
      clip-path: polygon(0 98%, 100% 98%, 100% 100%, 0 100%);
      transform: translate(0);
      filter: blur(1px);
   }

   10% {
      clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
      transform: translate3d(5px, 0, 0)
   }

   13% {
      clip-path: polygon(0 14%, 100% 14%, 100% 34%, 0 84%);
      transform: translateZ(0);
      filter: blur(1px);
   }

   13.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      transform: translate3d(5px, 0, 0);
      filter: blur(1px);
   }

   15% {
      clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
      transform: translate3d(5px, 0, 0)
   }

   20% {
      clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
      transform: translate3d(-5px, 0, 0);
      filter: blur(1px);
   }

   20.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      transform: translate3d(5px, 0, 0)
   }

   25% {
      clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
      transform: translate3d(5px, 0, 0);
      filter: blur(1px);
   }

   30% {
      clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
      transform: translate3d(-5px, 0, 0)
   }

   30.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      filter: blur(1px);
   }

   35% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(-5px)
   }

   40% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(5px);
      filter: blur(1px);
   }

   45% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(-5px)
   }

   50% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(0);
      filter: blur(1px);
   }

   55% {
      clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
      transform: translate3d(5px, 0, 0)
   }

   60% {
      clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
      transform: translateZ(0);
      filter: blur(1px);
      opacity: 1
   }

   60.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      opacity: 1;
      filter: blur(1px);
   }

   to {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      opacity: 1
   }
}

@keyframes glitch-anim-3 {
   0% {
      opacity: 1;
      transform: translateZ(0);
      clip-path: polygon(0 6%, 100% 6%, 100% 8%, 0 8%);
      filter: blur(1px);
   }

   2% {
      clip-path: polygon(0 75%, 100% 75%, 100% 97%, 0 97%);
      transform: translate(-5px)
   }

   6% {
      clip-path: polygon(0 72%, 100% 72%, 100% 100%, 0 100%);
      transform: translate(5px);
      filter: blur(1px);
   }

   8% {
      clip-path: polygon(0 82%, 100% 82%, 100% 100%, 0 100%);
      transform: translate(-5px)
   }

   9% {
      clip-path: polygon(0 82%, 100% 82%, 100% 100%, 0 100%);
      transform: translate(0);
      filter: blur(1px);
   }

   10% {
      clip-path: polygon(0 59%, 100% 59%, 100% 40%, 0 40%);
      transform: translate3d(5px, 0, 0)
   }

   13% {
      clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
      transform: translateZ(0);
      filter: blur(1px);
   }

   13.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      transform: translate3d(5px, 0, 0);
      filter: blur(1px);
   }

   15% {
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      transform: translate3d(5px, 0, 0)
   }

   20% {
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      transform: translate3d(-5px, 0, 0);
      filter: blur(1px);
   }

   20.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      transform: translate3d(5px, 0, 0)
   }

   25% {
      clip-path: polygon(0 95%, 100% 95%, 100% 36%, 0 36%);
      transform: translate3d(5px, 0, 0);
      filter: blur(1px);
   }

   30% {
      clip-path: polygon(0 95%, 100% 95%, 100% 36%, 0 36%);
      transform: translate3d(-5px, 0, 0)
   }

   30.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      filter: blur(1px);
   }

   35% {
      clip-path: polygon(0 58%, 100% 58%, 100% 87%, 0 87%);
      transform: translate(-5px)
   }

   40% {
      clip-path: polygon(0 58%, 100% 58%, 100% 80%, 0 80%);
      transform: translate(5px);
      filter: blur(1px);
   }

   45% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(-5px)
   }

   50% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(0);
      filter: blur(1px);
   }

   55% {
      clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
      transform: translate3d(5px, 0, 0)
   }

   60% {
      clip-path: polygon(0 15%, 100% 15%, 100% 0, 0 0);
      transform: translateZ(0);
      filter: blur(1px);
      opacity: 1
   }

   60.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      opacity: 1;
      filter: blur(1px);
   }

   to {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      opacity: 1
   }
}

.grecaptcha-badge {
   display: none !important;
}