@import '../../../scss/media';

.about_reviews {
   margin-top: 80px;

   &_list {
      &_images {
         display: flex;
         justify-content: center;
         align-items: center;
         position: relative;
         width: 100%;
         aspect-ratio: 1/1;
         height: auto;
         overflow: hidden;
         border-radius: 2px;

         &-image {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
      }

      &_content {
         display: flex;
         flex-direction: column;
         row-gap: 60px;
         justify-content: space-between;
         height: 100%;

         &_top {
            &_item {
               &-text {
                  color: #1A1A1A;
                  font-size: 40px;
                  font-size: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
                  // 40px > 30px
                  line-height: 40px;
                  line-height: clamp(2.125rem, 0.25rem + 1.875vw, 2.5rem);
                  // 40px > 34px
                  font-weight: 400;

                  @include desktop {
                     font-size: 30px;
                     line-height: 34px;
                  }
               }

               &-name {
                  color: #8A8A8A;
                  font-family: "Pitagon Sans Mono";
                  font-size: 16px;
                  font-size: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
                  // 16px > 14px
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  line-height: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
                  // 20px > 16px

                  margin-top: 20px;

                  @include desktop {
                     font-size: 14px;
                     font-weight: 400;
                     line-height: 16px;
                  }
               }

               &-btn {
                  margin-top: 40px;

                  @include hover {

                     .icon {
                        opacity: 1;
                        transition: .4s;
                     }

                     .case_btn-btn {
                        transform: translateX(32px);
                     }
                  }
               }
            }
         }

         &_bot {
            display: flex;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 20px;

            &-item {
               display: flex;
               justify-content: center;
               align-items: center;
               overflow: hidden;
               border-radius: 2px;
               width: 100%;
               max-width: 80px;
               aspect-ratio: 1/1;
               height: 80px;
               position: relative;

               cursor: pointer;

               .img {
                  width: 100%;
                  height: 100%;
               }

               .glitch {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
               }

               @include hover {
                  .glitch {
                     animation-duration: .4s;
                     animation-timing-function: linear;
                     animation-iteration-count: 1;
                     animation-name: glitch-anim-3;
                     animation-fill-mode: forwards;
                  }
               }

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }
         }
      }
   }
}