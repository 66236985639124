@import '../../../scss/media';

.about_team_block {
   margin-top: 104px;

   .about_team {
      row-gap: 40px;

      &_item {
         display: flex;
         flex-direction: column;
         row-gap: 10px;
         width: 100%;
         max-width: 16.66666666666667%;

         &-image {
            width: 100%;
            max-width: 100%;
            aspect-ratio: 1/1;
            height: 100%;
            overflow: hidden;
            border-radius: 2px;
            position: relative;

            .img {
               width: 100%;
               height: 100%;
            }

            .glitch {
               position: absolute;
               top: 0;
               left: 0;
               opacity: 0;
            }

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }

            @include hover {
               .glitch {
                  animation-duration: 1.3s;
                  animation-timing-function: linear;
                  animation-iteration-count: infinite;
                  animation-name: var(--glitch);
                  animation-direction: alternate;
               }
            }
         }

         &_content {
            display: flex;
            flex-direction: column;
            row-gap: 4px;

            &-name {
               color: #252525;
               font-size: 14px;
               font-weight: 400;
               line-height: 16px;
            }

            &-job {
               color: #8A8A8A;
               font-family: "Pitagon Sans Mono";
               font-size: 14px;
               font-weight: 400;
               line-height: 16px;
            }
         }
      }

      &_last {
         row-gap: 20px;
         position: relative;

         @include hover {
            svg {
               transform: rotate(180deg) scale(1.4);
            }
         }

         &-image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            aspect-ratio: 1/1;
            border-radius: 2px;
            background: #ECECEC;
            overflow: hidden;

            svg {
               width: 50px;
               height: 50px;
            }
         }

         &-title {
            text-align: center;
         }

         &-btn {
            display: flex;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            cursor: pointer;
         }
      }
   }
}