@media screen and (min-width: 768px) {
   .col-md-1 {
      width: 12.5%;
   }

   .col-md-2 {
      width: 25%;
   }

   .col-md-3 {
      width: 37.5%;
   }

   .col-md-4 {
      width: 50%;
   }

   .col-md-5 {
      width: 62.5%;
   }

   .col-md-6 {
      width: 75%;
   }

   .col-md-7 {
      width: 87.5%;
   }

   .col-md-8 {
      width: 100%;
   }

   .offset-md-1 {
      margin-left: 12.5%;
   }

   .offset-md-2 {
      margin-left: 25%;
   }

   .offset-md-3 {
      margin-left: 37.5%;
   }

   .offset-md-4 {
      margin-left: 50%;
   }

   .offset-md-5 {
      margin-left: 62.5%;
   }

   .offset-md-6 {
      margin-left: 75%;
   }

   .offset-md-7 {
      margin-left: 87.5%;
   }
}

@media screen and (min-width: 900px) {
   .col-lg-1 {
      width: 12.5%;
   }

   .col-lg-2 {
      width: 25%;
   }

   .col-lg-3 {
      width: 37.5%;
   }

   .col-lg-4 {
      width: 50%;
   }

   .col-lg-5 {
      width: 62.5%;
   }

   .col-lg-6 {
      width: 75%;
   }

   .col-lg-7 {
      width: 87.5%;
   }

   .col-lg-8 {
      width: 100%;
   }

   .offset-lg-1 {
      margin-left: 12.5%;
   }

   .offset-lg-2 {
      margin-left: 25%;
   }

   .offset-lg-3 {
      margin-left: 37.5%;
   }

   .offset-lg-4 {
      margin-left: 50%;
   }

   .offset-lg-5 {
      margin-left: 62.5%;
   }

   .offset-lg-6 {
      margin-left: 75%;
   }

   .offset-lg-7 {
      margin-left: 87.5%;
   }
}

@media screen and (min-width: 1200px) {
   .col-xl-1 {
      width: 12.5%;
   }

   .col-xl-2 {
      width: 25%;
   }

   .col-xl-3 {
      width: 37.5%;
   }

   .col-xl-4 {
      width: 50%;
   }

   .col-xl-5 {
      width: 62.5%;
   }

   .col-xl-6 {
      width: 75%;
   }

   .col-xl-7 {
      width: 87.5%;
   }

   .col-xl-8 {
      width: 100%;
   }

   .offset-xl-1 {
      margin-left: 12.5%;
   }

   .offset-xl-2 {
      margin-left: 25%;
   }

   .offset-xl-3 {
      margin-left: 37.5%;
   }

   .offset-xl-4 {
      margin-left: 50%;
   }

   .offset-xl-5 {
      margin-left: 62.5%;
   }

   .offset-xl-6 {
      margin-left: 75%;
   }

   .offset-xl-7 {
      margin-left: 87.5%;
   }
}