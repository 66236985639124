@import '../../../scss/media';

.animate_title {
   display: inline-block;
   color: #1A1A1A;
   font-size: 56px;
   font-size: clamp(2.5rem, -2.5rem + 5vw, 3.5rem);
   // 56px > 40px
   font-weight: 400;
   line-height: 66px;
   line-height: clamp(3.125rem, -1.875rem + 5vw, 4.125rem);
   // 66px > 50px

   max-width: 1270px;
   max-width: clamp(58.75rem, -44.375rem + 103.125vw, 79.375rem);
   // 1270px > 940px

   @include desktop {
      color: #1A1A1A;
      font-size: 40px;
      line-height: 50px;
      max-width: 940px;
   }

   @include tablet1200 {
      font-size: clamp(2rem, 0.5rem + 2.667vw, 2.5rem);
      // 40ppx > 32px
      line-height: clamp(2.5rem, 0.625rem + 3.333vw, 3.125rem);
      // 50px > 40px
   }

   @include tablet900 {
      font-size: 32px;
      font-size: clamp(1.5rem, -1.409rem + 6.061vw, 2rem);
      // 32px > 24px
      line-height: 40px;
      line-height: clamp(1.875rem, -1.761rem + 7.576vw, 2.5rem);
      // 40px > 30px
   }

   @include tablet {
      font-size: 24px;
      line-height: 30px;
   }
}

.animate_title {
   &-top {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      @include mobile {
         max-width: 570px;
      }

      p {
         display: block;
      }
   }

   .animate_title-top-line {
      overflow: hidden;
   }
}