@use '../../../scss/media';

.breadcrumbs {
   &_wrap {
      display: flex;
      flex-wrap: wrap;
      row-gap: 5px;
      column-gap: 5px;

      li {
         display: flex;
         column-gap: 5px;
         align-items: center;

         color: #8A8A8A;
         font-family: "Pitagon Sans Mono";
         font-size: 16px;
         font-size: media.fluid-media(16, 14);
         font-weight: 400;
         line-height: 22px;
         line-height: media.fluid-media(22, 16);
         text-transform: lowercase;

         @include media.desktop {
            font-size: 14px;
            line-height: 16px;
         }

         @include media.tablet1200 {
            font-size: 12px;
            line-height: 14px;
         }

         &:not(:first-of-type) {
            &::before {
               content: '-';
               display: block;
               color: inherit;
               font-family: inherit;
               font-weight: inherit;
               line-height: inherit;
               text-transform: inherit;
            }
         }

         a {

            transition: .4s;

            @include media.hover {
               color: #1A1A1A;
            }
         }

         a,
         span {
            color: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-transform: inherit;
         }
      }
   }
}