@import '../../scss/media';

.about_page {
   margin-top: 60px;
   margin-top: clamp(1.875rem, -7.5rem + 9.375vw, 3.75rem);
   // 60px > 30px

   @include desktop {
      margin-top: 30px;
   }

   &_wrap {
      &-title {
         max-width: 1100px;
         max-width: clamp(56.25rem, -6.25rem + 62.5vw, 68.75rem);
         // 1100px > 900px

         @include desktop {
            max-width: 900px;
         }
      }
   }
}