@use '../../../scss/media';

.front_first {
   margin-top: 150px;
   margin-top: clamp(7.5rem, -1.875rem + 9.375vw, 9.375rem);
   // 150px > 120px

   @include media.desktop {
      margin-top: 120px;
   }

   @include media.desktop1400 {
      margin-top: 120px;
      margin-top: clamp(6.25rem, -1.25rem + 10vw, 7.5rem);
      // 120px > 100px
   }

   @include media.tablet1200 {
      margin-top: 48px;
   }

   @include media.tablet {
      margin-top: 30px;
   }

   // @include media.tablet900 {
   //    margin-top: 130px;
   //    margin-top: clamp(7rem, 0.455rem + 13.636vw, 8.125rem);
   //    // 130px > 112px
   // }

   // @include media.tablet {
   //    margin-top: 112px;
   //    margin-top: clamp(6.25rem, 4.358rem + 5.505vw, 7rem);
   //    // 112px > 100px
   // }

   // @include media.mobile {
   //    margin-top: 100px;
   // }
}