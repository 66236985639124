@import '../scss/media';

.animate_page {
   position: fixed;
   z-index: 989999;
   background: #252323;

   padding: 0 20px;
   transform-origin: 50% 0%;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
   width: 100%;
   height: 100%;
   top: 0%;
   left: 0%;
   transform: scale(1, 0);
   padding-right: 16px;

   @include tablet1200 {
      padding-right: 20px;
   }

   &.fadeState {
      transform: scale(1, 1);
      transform-origin: 50% 100%;
      border-radius: 0vw 0vw 0% 0%;
      padding-right: 20px;
   }

   &.fadeIn {
      animation-name: animatePageIn;
      animation-duration: 1.4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;

      svg {
         opacity: 0;
      }
   }

   &.fadeOut {
      animation-name: animatePageOut;
      animation-duration: 1.4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;

      svg {
         opacity: 1;
         transition-delay: .15s;
      }
   }

   &.first-load {
      svg {
         opacity: 1;
      }
   }

   &.loaded {
      svg {
         opacity: 0;
      }
   }

   @keyframes animatePageIn {

      0% {
         transform: scale(1, 1);
         transform-origin: 50% 100%;
         border-radius: 0vw 0vw 0% 0%;
      }

      50% {
         transform: scale(1, 1);
         transform-origin: 50% 0%;
         border-radius: 0vw 0vw 0% 0%;
      }

      100% {
         transform-origin: 100% 0%;
         transform: scale(1, 0);
         border-radius: 0 0 100vw 100vw;
      }
   }

   @keyframes animatePageOut {
      0% {
         transform-origin: 50% 100%;
         transform: scale(1, 0);
         border-radius: 100dvw 100dvw 0 0;
      }

      50% {
         transform: scale(1, 1);
         transform-origin: 50% 100%;
         border-radius: 0vw 0vw 0% 0%;
      }

      100% {
         transform: scale(1, 1);
         transform-origin: 50% 100%;
         border-radius: 0vw 0vw 0% 0%;
      }
   }
}

.animate_page {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   /* Занимает всю высоту экрана */
   background-color: #f0f0f0;
   /* Цвет фона можно изменить */

   svg {
      width: 250px;
      height: auto;

      @include mobile {
         max-width: 200px;
      }

      path {
         transition: 0s;
         transition-delay: 0s;
      }

      pattern {
         path {
            animation: animWave 3.5s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
         }

         @keyframes animWave {
            0% {
               transform: translateX(0px);
            }

            100% {
               transform: translateX(250px);
            }
         }
      }
   }
}