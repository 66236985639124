@use '../../../scss/media';

.development_page_wrap_work {
   margin-top: 60px;
   display: flex;
   flex-direction: column;
   row-gap: 60px;
   row-gap: media.fluid-media(60, 40);

   @include media.desktop {
      row-gap: 40px;
   }

   @include media.tablet1200 {
      row-gap: 30px;
      margin-top: 30px;
   }

   &-title {
      color: #252525;
      font-family: "Forma DJR Cyrilic Variable";
      font-size: 28px;
      font-size: media.fluid-media(28, 18);
      font-weight: 400;
      line-height: 38px;
      line-height: media.fluid-media(38, 24);

      @include media.desktop {
         font-size: 18px;
         line-height: 24px;
         /* 133.333% */
      }

      @include media.tablet1200 {
         font-size: 12px;
         line-height: 14px;
      }
   }

   .development_page_wrap_work_list_block {
      display: flex;
      flex-direction: column;
      row-gap: 60px;
      row-gap: media.fluid-media(60, 40);
      align-items: center;
      justify-content: flex-start;

      @include media.desktop {
         row-gap: 40px;
      }

      @include media.tablet1200 {
         row-gap: 30px;
      }

      &-btn {
         display: inline-flex;
         justify-content: center;
         align-items: center;

         padding: 10px 20px;
         border-radius: 50px;
         background: #494949;
         border: 1px solid currentColor;

         color: #ECECEC;
         font-family: "Forma DJR Cyrilic Variable";
         font-size: 20px;
         font-size: media.fluid-media(20, 18);
         font-weight: 400;
         line-height: 28px;
         line-height: media.fluid-media(28, 24);

         transition: .4s;

         @include media.desktop {
            font-size: 18px;
            line-height: 24px;
         }

         @include media.tablet1200 {
            font-size: 16px;
            line-height: 22px;
         }

         @include media.hover {
            background: transparent;
            color: #494949;
         }
      }
   }

   &_list {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      row-gap: media.fluid-media(40, 30);
      width: 100%;
      position: relative;

      @include media.desktop {
         row-gap: 30px;
      }

      @include media.tablet1200 {
         row-gap: 24px;
      }

      @include media.mobile {
         margin-left: 0px;
         margin-right: 0px;
      }

      &_it {

         @include media.mobile {
            row-gap: 20px;
            padding-top: 24px;
            border-top: 1px solid #ECECEC;

            --bs-gutter-x: 0
         }

         &:last-child {
            .development_page_wrap_work_list_it-text {
               border-bottom: 1px solid #ECECEC;
               padding-bottom: 40px;
               padding-bottom: media.fluid-media(40, 30);

               @include media.desktop {
                  padding-bottom: 30px;
               }

               @include media.tablet1200 {
                  padding-bottom: 24px;
               }
            }
         }

         &-num {
            color: #252525;
            font-family: "Pitagon Sans Mono";
            font-size: 28px;
            font-size: media.fluid-media(28, 18);
            font-weight: 200;
            line-height: 34px;
            line-height: media.fluid-media(34, 24);

            @include media.desktop {
               font-size: 18px;
               line-height: 24px;
            }

            @include media.tablet1200 {
               font-size: 14px;
               line-height: 18px;
            }
         }

         &-title {
            color: #252525;
            font-family: "Forma DJR Cyrilic Variable";
            font-size: 30px;
            font-size: media.fluid-media(30, 22);
            font-weight: 400;
            line-height: 34px;
            line-height: media.fluid-media(34, 28);

            @include media.desktop {
               font-size: 22px;
               line-height: 28px;
            }

            @include media.tablet1200 {
               font-size: 18px;
               line-height: 26px;
            }
         }

         &-text {
            color: #252525;
            font-family: "Forma DJR Cyrilic Variable";
            font-size: 22px;
            font-size: media.fluid-media(22, 18);
            font-weight: 400;
            line-height: 32px;
            line-height: media.fluid-media(32, 24);
            border-top: 1px solid #ECECEC;
            padding-top: 40px;
            padding-top: media.fluid-media(40, 30);

            @include media.desktop {
               padding-top: 30px;

               font-size: 18px;
               line-height: 24px;
               /* 133.333% */
            }

            @include media.tablet1200 {
               padding-top: 24px;

               font-size: 16px;
               line-height: 20px;
            }

            @include media.mobile {
               border: unset;
               padding: unset;
               margin-top: 4px;
            }

            >* {

               &:first-child {
                  margin-top: 0px;
               }

               &:last-child {
                  margin-bottom: 0px;
               }
            }

            p {
               margin: 18px 0;

               @include media.tablet1200 {
                  margin: 12px 0;
               }
            }

            Ul {
               margin: 18px 0;
               padding-left: 15px;

               @include media.tablet1200 {
                  margin: 12px 0;
               }

               li {
                  position: relative;
                  padding-left: 20px;

                  @include media.desktop {
                     padding-left: 15px;
                  }

                  &::after {
                     content: '';
                     width: 5px;
                     height: 5px;
                     background: #252525;
                     position: absolute;
                     top: calc(50% - 1px);
                     transform: translateY(-50%);
                     left: 0;

                     @include media.desktop {
                        width: 4px;
                        height: 4px;
                     }
                  }
               }
            }
         }
      }
   }
}