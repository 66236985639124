@import '../../scss/media';

.header {
   width: 100%;

   @include tablet1200 {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      background: #fff;
   }

   &_wrap {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;

      @include desktop1400 {
         padding-top: 16px;
      }

      @include tablet1200 {
         padding-top: 16px;
         padding-bottom: 16px;
         align-items: center;
      }

      &-logo {
         display: flex;
         width: 122px;
         width: clamp(5.688rem, -4rem + 9.688vw, 7.625rem);
         // 122px > 91px

         position: relative;
         z-index: 99;

         @include desktop {
            width: 91px;
         }

         @include desktop1400 {
            width: 88px;
         }

         svg {
            width: 100%;
            height: auto;
            max-width: 100%;
         }

         &.active {
            svg {
               path {
                  fill: #fff;
               }
            }
         }
      }

      &_contact_us {
         display: flex;
         align-items: center;
         column-gap: 17px;

         @include desktop {
            column-gap: 16px;
         }

         &-icon {
            display: flex;

            svg {
               g {
                  circle {
                     animation-name: animateCircle;
                     animation-duration: 2.5s;
                     animation-iteration-count: infinite;
                     animation-fill-mode: forwards;
                     animation-timing-function: linear;
                  }
               }
            }
         }

         &-it {
            color: #1A1A1A;
            font-family: "Pitagon Sans Mono";
            font-size: 20px;
            font-size: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
            // 20px > 16px
            font-weight: 300;
            line-height: 22px;
            transition: .4s;

            @include hover {
               color: #8A8A8A;
            }

            @include desktop {
               font-size: 16px;
               font-weight: 300;
               line-height: 22px;
            }

            &::after {
               content: ' ->';
               display: inline;
               color: inherit;
               font-family: inherit;
               font-size: inherit;
               font-style: inherit;
               font-weight: inherit;
               line-height: inherit;
            }
         }
      }

      &_tags {
         display: flex;
         column-gap: 20px;

         @include tablet1200 {
            column-gap: 10px;
         }

         &-title {
            color: #8A8A8A;
            font-family: "Pitagon Sans Mono";
            font-size: 20px;
            font-size: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
            // 20px > 16px
            font-weight: 300;
            line-height: 26px;
            line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
            // 26px > 22px

            width: 100%;
            max-width: calc(33.33333333333333% - 10px);

            @include desktop {
               font-size: 16px;
               line-height: 22px;
            }
         }

         nav {
            width: 100%;
            max-width: calc(66.66666666666667% - 10px);

            ul {
               display: flex;
               align-items: flex-start;
               justify-content: flex-start;
               flex-wrap: wrap;
               column-gap: 20px;
               row-gap: 10px;
               row-gap: clamp(0.375rem, -0.875rem + 1.25vw, 0.625rem);
               // 10px > 6px

               @include desktop {
                  row-gap: 6px;
               }

               @include desktop1400 {
                  row-gap: 11px;
               }

               li {
                  width: 100%;
                  max-width: calc(50% - 10px);
                  display: inline-flex;

                  &:last-of-type {
                     .link_tag {

                        &::after {
                           display: none;
                        }

                        &::before {
                           width: 100%;
                        }
                     }
                  }

                  &.current {
                     .link_tag {
                        color: #1A1A1A;
                     }
                  }

                  &:not(.current) {
                     .link_tag {
                        @include hover {
                           &::before {
                              width: 0px;
                           }

                           color: #1A1A1A;
                        }
                     }
                  }

                  .link_tag {
                     position: relative;
                     color: #8A8A8A;
                     font-family: "Pitagon Sans Mono";
                     font-size: 20px;
                     font-size: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
                     // 20px > 16px
                     font-weight: 300;
                     line-height: 26px;
                     line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
                     // 26px > 22px

                     cursor: pointer;
                     transition: .4s;

                     @include desktop {
                        font-size: 16px;
                        line-height: 22px;
                     }



                     &::after {
                        content: ' ->';
                        display: inline;

                        color: inherit;
                        font-family: inherit;
                        font-size: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                     }

                     &::before {
                        content: '';
                        display: block;
                        width: calc(100% - 40px);
                        width: calc(100% - clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem));
                        // 40px > 30px
                        height: 1px;
                        background-color: #8A8A8A;
                        position: absolute;
                        bottom: -1px;
                        left: 0;

                        transition: .4s;

                        @include desktop {
                           width: calc(100% - 30px);
                        }
                     }
                  }
               }
            }
         }
      }

      &_nav {
         display: flex;
         justify-content: flex-end;

         nav {
            ul {
               display: flex;
               column-gap: 90px;
               column-gap: clamp(3.75rem, -5.625rem + 9.375vw, 5.625rem);
               // 90px > 60px

               @include desktop {
                  column-gap: 60px;
               }

               @include desktop1400 {
                  column-gap: 24px;
               }

               li {
                  display: flex;

                  a {

                     color: #252525;
                     text-align: right;
                     font-family: "Pitagon Sans Mono";
                     font-size: 20px;
                     font-size: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
                     // 20px > 16px
                     font-weight: 300;
                     line-height: 22px;

                     transition: .4s;

                     @include hover {
                        color: #8A8A8A;
                     }

                     @include desktop {
                        font-size: 16px;
                        line-height: 22px;
                     }
                  }
               }
            }
         }
      }

      .contact_col {
         @include tablet1200 {
            display: none;
         }
      }

      .nav_tags {
         width: calc(37.5% + 20px);

         @include desktop1400 {
            width: calc(37.5% + 85px);
         }

         @include tablet1200 {
            display: none;
         }
      }

      .nav_main {
         width: calc(25% - 20px);

         @include desktop1400 {
            width: calc(25% - 85px);
         }

         @include tablet1200 {
            display: none;
         }
      }

      .burger_menu {
         position: fixed;
         width: 100%;
         height: 100dvh;
         background: #1A1A1A;
         top: 0;
         left: -100%;
         padding-top: 62px;

         display: flex;
         flex-direction: column;
         justify-content: space-between;
         row-gap: 40px;
         padding-bottom: 13px;
         padding-left: 20px;
         padding-right: 20px;
         z-index: 98;

         @include tablet {
            padding-left: 17px;
            padding-right: 17px;
         }

         @include mobile {
            padding-left: 13px;
            padding-right: 13px;
         }

         transition: .4s;

         &.active {
            opacity: 1;
            left: 0%;
         }

         nav {
            margin-top: 131px;

            ul {
               display: flex;
               flex-direction: column;
               align-items: center;
               row-gap: 24px;

               li {
                  display: flex;

                  a {
                     color: #FFF;
                     text-align: center;
                     font-size: 32px;
                     font-weight: 400;
                     line-height: 30px;
                  }
               }
            }
         }

         .burger_menu_contact {
            width: 100%;

            &-btn {
               display: flex;
               justify-content: center;
               align-items: center;
               column-gap: 16px;
               padding: 16px;
               border-radius: 12px;
               background: #F1F1F1;

               color: #1A1A1A;
               font-family: "Pitagon Sans Mono";
               font-size: 16px;
               font-weight: 300;
               line-height: 22px;

               .icon {
                  display: flex;

                  svg {
                     g {
                        circle {
                           animation-name: animateCircle;
                           animation-duration: 2.5s;
                           animation-iteration-count: infinite;
                           animation-fill-mode: forwards;
                           animation-timing-function: linear;
                        }
                     }
                  }
               }
            }
         }
      }

      .burger_btn {
         display: none;
         justify-content: flex-end;
         max-width: 50%;
         position: relative;
         z-index: 99;

         @include tablet1200 {
            display: flex;
         }

         &-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;

            cursor: pointer;

            &.active {
               svg {
                  transform: rotate(-135deg);

                  rect {
                     fill: #fff;
                     transition-delay: .2s;
                  }
               }
            }

            svg {
               width: 100%;
               height: 100%;

               rect {
                  transition: .4s;
                  transition-delay: 0s;
               }
            }
         }
      }
   }
}