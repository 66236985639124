@import '../../scss/media';

.footer {
   background: #303030;
   margin-top: 120px;
   margin-top: clamp(5rem, -7.5rem + 12.5vw, 7.5rem);
   // 120px > 80px
   padding-top: 70px;
   padding-top: clamp(3.125rem, -3.125rem + 6.25vw, 4.375rem);
   // 70px > 50px
   padding-bottom: 110px;
   padding-bottom: clamp(3.75rem, -11.875rem + 15.625vw, 6.875rem);
   // 110px > 60px

   @include desktop {
      margin-top: 80px;
      padding-top: 50px;
      padding-bottom: 60px;
   }

   @include mobile {
      padding-top: 40px;
      padding-bottom: 40px;
   }

   &.sticky_footer_block {
      margin-top: 0px;
   }

   &_wrap {
      row-gap: 18px;

      @include desktop {
         row-gap: 40px;
      }

      @include mobile {
         row-gap: 20px;
      }

      &-title {
         color: #8A8A8A;
         font-family: "Pitagon Sans Mono";
         font-size: 16px;
         font-size: clamp(0.875rem, 0.25rem + 0.625vw, 1rem);
         // 16px > 14px
         font-weight: 400;
         line-height: 18px;
         line-height: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
         // 18px > 16px

         @include desktop {
            font-size: 14px;
            line-height: 16px;
         }
      }

      &_left {
         display: flex;
         flex-direction: column;
         justify-content: flex-end;
         height: 100%;
         row-gap: 50px;
         row-gap: clamp(2.5rem, -0.625rem + 3.125vw, 3.125rem);
         // 50px > 40px

         @include desktop {
            row-gap: 40px;
            height: auto;
            justify-content: flex-start;
            margin-top: 20px;
         }

         @include mobile {
            margin-top: 0px;
         }

         &-title {
            color: #F0F0F0;
            font-family: "Forma DJR Cyrilic Variable";
            font-size: 56px;
            font-size: clamp(2.5rem, -2.5rem + 5vw, 3.5rem);
            // 56px > 40px
            font-weight: 400;
            line-height: 66px;
            line-height: clamp(2.5rem, -5.625rem + 8.125vw, 4.125rem);
            // 66px > 40px

            @include desktop {
               font-size: 40px;
               line-height: 40px;
            }

            @include mobile {
               font-size: 24px;
               line-height: 30px;
            }
         }

         &_manager {
            display: flex;
            column-gap: 20px;

            &-avatar {
               width: 100%;
               height: 100%;
               max-width: 90px;
               max-width: clamp(3.75rem, -5.625rem + 9.375vw, 5.625rem);
               // 90px > 60px
               overflow: hidden;
               aspect-ratio: 1/1;
               border-radius: 50%;

               @include desktop {
                  max-width: 60px;
               }

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            &_meta {
               display: flex;
               align-items: center;
               width: 100%;
               max-width: calc(100% - 90px - 20px);
               max-width: calc(100% - clamp(3.75rem, -5.625rem + 9.375vw, 5.625rem) - 20px);
               // 90px > 60px

               color: #8A8A8A;
               font-family: "Pitagon Sans Mono";
               font-size: 22px;
               font-size: clamp(1.125rem, -0.125rem + 1.25vw, 1.375rem);
               // 22px > 18px
               font-weight: 400;
               line-height: 26px;
               line-height: clamp(1.5rem, 0.875rem + 0.625vw, 1.625rem);
               // 26px > 24px

               @include desktop {
                  max-width: calc(100% - 60px - 20px);

                  font-size: 18px;
                  line-height: 24px;
               }
            }
         }
      }

      &_right {
         display: flex;
         flex-direction: column;
         row-gap: 132px;
         row-gap: clamp(3.125rem, -22.5rem + 25.625vw, 8.25rem);
         // 132px > 50px

         @include desktop {
            row-gap: 50px;
         }

         @include mobile {
            row-gap: 32px;
         }

         form {
            width: 100%;
            position: relative;

            .form_response {
               display: flex;
               justify-content: center;
               align-items: center;
               flex-direction: column;
               row-gap: 20px;
               row-gap: fluid-media(20, 15);
               padding: 30px;
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               background: rgba(48, 48, 48, 1);
               z-index: 2;
               border: 1px solid rgba(138, 138, 138, 1);
               border-radius: 5px;
               overflow: hidden;

               @include desktop {
                  row-gap: 15px;
               }

               @include tablet {
                  padding: 20px;
                  row-gap: 10px;
               }

               &-title {
                  color: #FFF;
                  text-align: center;
                  font-family: "Forma DJR Cyrilic Variable";
                  font-size: 30px;
                  font-size: fluid-media(30, 26);
                  font-weight: 400;
                  line-height: 34px;
                  line-height: fluid-media(34, 30);
                  max-width: 510px;
                  width: 100%;

                  @include desktop {
                     font-size: 26px;
                     line-height: 30px;
                     max-width: 510px;
                  }

                  @include mobile {
                     font-size: 22px;
                     line-height: 26px;
                  }
               }

               &-text {
                  color: #FFF;
                  text-align: center;
                  font-family: "Forma DJR Cyrilic Variable";
                  font-size: 18px;
                  font-size: fluid-media(18, 16);
                  font-weight: 400;
                  line-height: 24px;
                  line-height: fluid-media(25, 22);
                  max-width: 495px;
                  width: 100%;

                  @include desktop {
                     font-size: 16px;
                     line-height: 22px;
                     max-width: 440px;
                  }

                  @include mobile {
                     font-size: 14px;
                     line-height: 20px;
                     max-width: 350px;
                  }
               }

               &.form_loading {

                  .form_loading-text {
                     color: #8A8A8A;
                     text-align: center;
                     font-family: "Forma DJR Cyrilic Variable";
                     font-size: 22px;
                     font-size: fluid-media(22, 20);
                     font-weight: 400;
                     line-height: 28px;
                     line-height: fluid-media(28, 26);

                     @include desktop {
                        font-size: 20px;
                        line-height: 26px;
                     }

                     @include mobile {
                        font-size: 18px;
                        line-height: 24px;
                     }
                  }

                  .loader {
                     width: 26px;
                     height: 26px;
                     border: 2px solid #8A8A8A;
                     border-bottom-color: #fff;
                     border-right-color: #fff;
                     border-radius: 50%;
                     display: inline-block;
                     box-sizing: border-box;
                     animation: rotation 1s linear infinite;
                  }

                  @keyframes rotation {
                     0% {
                        transform: rotate(0deg);
                     }

                     100% {
                        transform: rotate(360deg);
                     }
                  }
               }
            }
         }

         .footer_form {
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;

            @include desktop {
               row-gap: 20px;
            }

            @include mobile {
               row-gap: 10px;
            }

            &_left {
               display: flex;
               flex-direction: column;
               row-gap: 16px;
               row-gap: clamp(0.5rem, -2rem + 2.5vw, 1rem);
               // 16px > 8px
               width: 100%;
               max-width: 50%;

               @include desktop {
                  row-gap: 8px;
               }

               @include mobile {
                  max-width: 100%;
                  row-gap: 10px;
               }
            }

            .PhoneInput {
               width: 100%;
               height: 100%;

               .PhoneInputCountry {
                  position: absolute;
                  left: 20px;
                  top: 50%;
                  transform: translateY(-50%);
               }
            }

            &-input {
               width: 100%;
               max-width: 100%;
               height: 62px;
               height: clamp(3.5rem, 1.625rem + 1.875vw, 3.875rem);
               // 62px > 56px

               position: relative;

               @include desktop {
                  height: 56px;
               }

               @include mobile {
                  height: 52px;
               }

               input {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                  border: 1px solid #494949;
                  background: #303030;
                  padding: 0 55px 0 20px;

                  color: #fff;
                  font-size: 18px;
                  font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
                  // 18px > 14px
                  font-weight: 400;
                  line-height: 16px;

                  @include desktop {
                     font-size: 14px;
                     line-height: 16px;
                  }

                  &::placeholder {
                     color: #8A8A8A;

                     font-size: inherit;
                     font-weight: inherit;
                     line-height: inherit;
                  }
               }

               &.tel {
                  .correct_icon {
                     opacity: 0;

                     &.correct {
                        opacity: 1;
                     }

                     &.incorrect {
                        opacity: 1;
                     }
                  }
               }

               .PhoneInput {

                  &--focus {
                     .PhoneInputCountryIcon {

                        svg {
                           opacity: 0;

                           g {
                              opacity: 1;
                              display: none;

                              path {
                                 stroke: #fff !important;
                                 opacity: 1;
                              }
                           }

                           .PhoneInputInternationalIconPhone {
                              fill: #fff !important;
                              opacity: 1;
                           }
                        }

                     }

                     .PhoneInputCountrySelectArrow {
                        opacity: 1 !important;
                     }
                  }

                  input {
                     padding-left: 74px;
                  }

                  .PhoneInputCountry {
                     .PhoneInputCountryIcon {
                        outline: none;
                        border: none;
                        box-shadow: unset;

                        select {
                           &:focus-visible {
                              outline: none;
                           }
                        }

                        svg {
                           opacity: 1;
                           transform: translateX(5px);

                           g {
                              opacity: 1;
                              display: none;

                              path {
                                 stroke: #8A8A8A;
                                 opacity: 1;
                                 transition: .3s;
                              }

                              line {
                                 opacity: 0;
                              }
                           }

                           .PhoneInputInternationalIconPhone {
                              fill: #8A8A8A;
                              opacity: 1;
                              transition: .3s;
                           }
                        }
                     }

                     .PhoneInputCountrySelectArrow {
                        position: relative;
                        border: unset;
                        width: 6px;
                        height: 5px;
                        display: flex;
                        transform: unset;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='5' viewBox='0 0 6 5' fill='none'%3E%3Cpath d='M2.04956 4.5C2.47198 5.16667 3.52802 5.16667 3.95044 4.5L5.85133 1.5C6.27375 0.833333 5.74572 0 4.90089 0H1.09911C0.254275 0 -0.273748 0.833333 0.148671 1.5L2.04956 4.5Z' fill='white'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        margin-left: 8px;
                        opacity: .4;
                        transition: .3s;
                     }
                  }

                  &.correct {
                     input {
                        border-color: #fff;
                        color: #fff;
                     }
                  }

                  &.incorrect {
                     input {
                        border-color: #FB8B8B;
                        color: #FB8B8B;

                        &:valid {
                           border-color: #FB8B8B;
                           color: #FB8B8B;
                        }
                     }
                  }
               }
            }

            &-check {
               .custom-checkbox {
                  position: absolute;
                  z-index: -1;
                  opacity: 0;
               }

               .custom-checkbox+label {
                  display: inline-flex;
                  align-items: center;
                  user-select: none;

                  color: #9F9F9F;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;

                  cursor: pointer;
               }

               .custom-checkbox+label::before {
                  content: '';
                  display: inline-block;
                  width: 14px;
                  height: 14px;
                  flex-shrink: 0;
                  flex-grow: 0;
                  border: 1px solid rgba(159, 159, 159, 1);
                  border-radius: 4px;
                  margin-right: 14px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-size: 50% 50%;
               }

               .custom-checkbox:checked+label::before {
                  border-color: rgba(159, 159, 159, 1);
                  background-color: rgba(159, 159, 159, 1);
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
               }
            }

            input:not([type=submit]),
            textarea {
               transition: border-color .4s;

               @include hover {
                  border-color: #fff;
               }

               &:valid {
                  border-color: #FFF;
                  color: #fff;

                  &::placeholder {
                     color: #fff;
                  }
               }

               &:focus-within {
                  border-color: #fff;
                  color: #fff;

                  &::placeholder {
                     color: #fff;
                  }
               }

               &:focus-within:invalid {
                  border-color: #FB8B8B;
                  color: #FB8B8B;

                  &::placeholder {
                     color: #FB8B8B;
                  }
               }

               &:valid+.correct_icon:before {
                  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='white' /%3E%3Cpath d='M6.66602 10.2733L8.35814 12.7676C8.92181 13.5984 10.1136 13.4966 10.5453 12.5807L13.3327 6.66699' stroke='%23494949' strokeWidth='2' strokeLinecap='round' /%3E%3C/svg%3E");
               }

               &:invalid+.correct_icon:before {
                  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23FB8B8B' /%3E%3Cpath d='M7 13L13 7.00001' stroke='white' strokeWidth='2' strokeLinecap='round' /%3E%3Cpath d='M7 7L13 13' stroke='white' strokeWidth='2' strokeLinecap='round' /%3E%3C/svg%3E");
                  opacity: 0;
               }

               &:focus-within+.correct_icon:before {
                  opacity: 1;

               }
            }

            &_right {
               width: 100%;
               max-width: 50%;

               @include mobile {
                  max-width: 100%;
                  aspect-ratio: 349/108;
               }
            }

            &-area {
               width: 100%;
               height: 100%;
               position: relative;

               textarea {
                  width: 100%;
                  height: 100%;
                  resize: none;
                  padding: 20px 55px 20px 20px;

                  border-radius: 5px;
                  border: 1px solid #494949;
                  background: #303030;

                  color: #fff;
                  font-size: 18px;
                  font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
                  // 18px > 14px
                  font-weight: 400;
                  line-height: 16px;

                  @include desktop {
                     font-size: 14px;
                     line-height: 16px;
                  }

                  @include mobile {
                     padding: 18px 20px;
                  }

                  &::placeholder {
                     color: #8A8A8A;

                     font-size: inherit;
                     font-weight: inherit;
                     line-height: inherit;
                  }
               }
            }

            &-btn {
               width: 100%;
               height: 56px;
               margin-top: 10px;
               margin-top: clamp(0rem, -4.805rem + 4.525vw, 0.625rem);
               // 10px > 0px

               @include mobile {
                  height: 52px;
               }

               input {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  border-radius: 12px;
                  border: 1px solid #FFF;
                  background: #FFF;
                  cursor: pointer;

                  color: #323232;
                  text-align: center;
                  font-size: 18px;
                  font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
                  // 18px > 14px
                  font-weight: 400;
                  line-height: 16px;


                  transition: .4s;

                  @include desktop {
                     font-size: 14px;
                     line-height: 16px;
                  }

                  @include mobile {
                     border-radius: 5px;
                  }

                  @include hover {
                     opacity: .7;
                  }

                  &:disabled {
                     user-select: none;
                     cursor: not-allowed;
                     opacity: .6;
                  }
               }
            }

            .correct_icon {
               position: absolute;
               top: 21px;
               right: 20px;
               display: flex;
               width: 20px;
               height: 20px;
               user-select: none;
               pointer-events: none;

               &::before {
                  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='white' /%3E%3Cpath d='M6.66602 10.2733L8.35814 12.7676C8.92181 13.5984 10.1136 13.4966 10.5453 12.5807L13.3327 6.66699' stroke='%23494949' strokeWidth='2' strokeLinecap='round' /%3E%3C/svg%3E");
                  display: flex;
                  width: 100%;
                  height: 100%;
               }

               &.correct {
                  &::before {
                     content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='white' /%3E%3Cpath d='M6.66602 10.2733L8.35814 12.7676C8.92181 13.5984 10.1136 13.4966 10.5453 12.5807L13.3327 6.66699' stroke='%23494949' strokeWidth='2' strokeLinecap='round' /%3E%3C/svg%3E");
                  }
               }

               &.incorrect {
                  &::before {
                     content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23FB8B8B' /%3E%3Cpath d='M7 13L13 7.00001' stroke='white' strokeWidth='2' strokeLinecap='round' /%3E%3Cpath d='M7 7L13 13' stroke='white' strokeWidth='2' strokeLinecap='round' /%3E%3C/svg%3E");
                  }
               }
            }
         }

         &_bot {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &_social {
               display: flex;
               column-gap: 20px;
               flex-wrap: wrap;
               row-gap: 10px;
               max-width: 50%;
               width: 100%;

               @include mobile {
                  column-gap: 10px;
               }

               &-it {
                  display: flex;
                  width: 100%;
                  max-width: 42px;
                  transition: .4s;

                  @include mobile {
                     max-width: 36px;
                  }

                  @include hover {
                     svg path {
                        fill: #F0F0F0;
                     }
                  }

                  svg {
                     width: 100%;
                     height: auto;
                     object-fit: cover;
                  }
               }
            }

            &_info {
               display: flex;
               flex-direction: column;
               align-items: flex-end;
               justify-content: flex-start;
               row-gap: 4px;
               max-width: 50%;
               width: 100%;

               @include desktop {
                  row-gap: 8px;
               }

               >* {
                  color: #8A8A8A;
                  text-align: right;
                  font-size: 18px;
                  font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
                  // 18px > 14px
                  font-weight: 400;
                  line-height: 20px;
                  line-height: clamp(1rem, -0.25rem + 1.25vw, 1.25rem);
                  // 20px > 16px

                  @include desktop {
                     font-size: 14px;
                     line-height: 16px;
                  }
               }

               a,
               button {
                  transition: .4s;

                  @include hover {
                     color: #F0F0F0;
                  }
               }

               button {
                  position: relative;
                  cursor: pointer;

                  &::after {
                     content: '';
                     display: block;
                     width: 100%;
                     height: 1px;
                     background: #8A8A8A;
                     position: absolute;
                     left: 0;
                     bottom: -1px;
                  }
               }
            }
         }
      }
   }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
   -webkit-box-shadow: 0 0 0 1000px #303030 inset !important;
   /* Цвет фона */
   -webkit-text-fill-color: #fff !important;
   /* Цвет текста */
}