@use '../../../scss/media';

.development_page {
   margin-top: 40px;

   @include media.desktop {
      margin-top: 60px;
   }

   @include media.tablet1200 {
      margin-top: 12px;
   }

   @include media.mobile {
      margin-top: 20px;
   }

   .development_page_wrap {
      margin-top: 40px;
      margin-top: media.fluid-media(40, 30);

      @include media.desktop {
         margin-top: 30px;
      }

      @include media.tablet1200 {
         margin-top: 20px;
      }
   }
}