@import '../../../scss/media';

.about_benefits {
   margin-top: 80px;
   justify-content: space-between;

   .col-xl-2 {

      &:first-of-type {

         .about_benefits_item {
            &::before {
               display: none;
            }
         }
      }
   }

   &_item {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      position: relative;
      height: 100%;

      &::before {
         content: '';
         display: block;
         width: calc(50% + (var(--bs-gutter-x) / 2) - 60px);
         height: 1px;
         background: #8A8A8A;
         position: absolute;
         left: calc(-50% - (var(--bs-gutter-x)) + 30px);
         top: 50%;
         transform: translateY(-50%);
      }

      &-num {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 30px;
         height: 30px;
         aspect-ratio: 1/1;
         border-radius: 50%;
         border: 1px solid #8A8A8A;

         color: #8A8A8A;
         text-align: center;
         font-size: 18px;
         font-weight: 400;
         line-height: 24px;
      }

      &-text {
         color: #8A8A8A;
         font-size: clamp(1.125rem, -0.125rem + 1.25vw, 1.375rem);
         // 22px > 18px
         font-weight: 400;
         line-height: 28px;
         line-height: clamp(1.5rem, 0.25rem + 1.25vw, 1.75rem);
         // 28px / 24px

         @include desktop {
            font-size: 18px;
            line-height: 24px;
         }
      }
   }
}